import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ItemStore from '../../stores/ItemStore'
import OrderStore from '../../stores/OrderStore'
import commaNumber from 'comma-number'
import SelectOptionButton from '../buttons/SelectOptionButton'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { BottomSheet } from 'react-spring-bottom-sheet'
import ImageManager from '../../images/ImageManager'
import '../../screens/ItemScreen.css'
import { ToastContainer, toast, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import UserStore from '../../stores/UserStore'
import dynamicLinkUtils from '../../utils/dynamicLinkUtils'

const ItemOrderBottomSheet = observer(() => {
  const history = useHistory()
  const [displayedPrice, setDisplayedPrice] = useState('')
  const notify = (
    message,
    timeSpan = 1500,
    position = toast.POSITION.TOP_CENTER,
  ) =>
    toast(message, {
      autoClose: timeSpan,
      closeButton: false,
      position,
      // position: toast.POSITION.BOTTOM_CENTER,
    })
  useEffect(() => {
    if (
      OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
        ?.optionIndices
    ) {
      const optionIndices =
        OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
          .optionIndices
      if (
        Object.keys(optionIndices).length ===
        OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]?.itemInfo
          .optionsInfo.totalOptions.length
      ) {
        let selectedPriceOption = JSON.parse(
          JSON.stringify(
            OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
              ?.itemInfo.optionsInfo?.optionPrices,
          ),
        )
        Object.values(optionIndices).forEach((optionIndex) => {
          selectedPriceOption = selectedPriceOption[optionIndex]
        })
        // OrderStore.setSelectedOption({ ...optionIndices, ...selectedPriceOption })
        OrderStore.setItemSelectedOptionByIndex(
          {
            ...optionIndices,
            ...selectedPriceOption,
          },
          OrderStore.currentItemIndex,
        )
        if (
          selectedPriceOption.stockNumber <
          OrderStore.currentUserOrdersList[OrderStore.currentItemIndex].quantity
        ) {
          OrderStore.setItemQuantityByIndex(
            selectedPriceOption.stockNumber,
            OrderStore.currentItemIndex,
          )
          // toastRef.current.show(
          //   `현재 해당 상품의 재고는 ${selectedPriceOption.stockNumber}개입니다.`,
          //   1000,
          // )
        }
      }
    }
  }, [
    OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
      ?.optionIndices,
  ])
  useEffect(() => {
    console.log(`bottom sheet useeffect activated`)
    const optionsInfo =
      OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]?.itemInfo
        .optionsInfo
    let individualPurchasePrice = 0
    let teamPurchasePrice = 0
    const isInitialTeamDealLinkInvited = !!(
      OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
        ?.isInitialTeamDealLinkInvited &&
      OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]?.itemInfo
        .invitorId &&
      OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]?.itemInfo
        .invitedOrderId
    )
    if (optionsInfo?.optionNames) {
      let optionPrices = [...optionsInfo.optionPrices]
      let finalOptionPrices = [...optionsInfo.optionPrices]
      let testOption = optionPrices[0]
      while (testOption.length) {
        finalOptionPrices = []
        optionPrices.forEach((option) => {
          finalOptionPrices = finalOptionPrices.concat(option)
        })
        optionPrices = [...finalOptionPrices]
        testOption = finalOptionPrices[0]
      }
      if (finalOptionPrices.filter((option) => !option.sold_out).length > 0) {
        teamPurchasePrice = Math.min(
          ...finalOptionPrices
            .filter((option) => !option.sold_out)
            .map((option) => option.teamPurchasePrice),
        )
        individualPurchasePrice = finalOptionPrices.find(
          (option) => option.teamPurchasePrice === teamPurchasePrice,
        ).individualPurchasePrice
      } else {
        let tempOptionPrice = optionsInfo.optionPrices
        for (let i = 0; i < optionsInfo.optionNames.length; i += 1) {
          tempOptionPrice = tempOptionPrice[0]
        }
        individualPurchasePrice = tempOptionPrice.individualPurchasePrice
        teamPurchasePrice = tempOptionPrice.teamPurchasePrice
      }
    } else {
      individualPurchasePrice =
        OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
          ?.selectedOption.individualPurchasePrice
      teamPurchasePrice =
        OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
          ?.selectedOption.teamPurchasePrice
    }
    if (OrderStore.currentItemTotalPrice) {
      OrderStore.setItemTotalPriceByIndex(
        OrderStore.currentItemTotalPrice,
        OrderStore.currentItemIndex,
      )
      setDisplayedPrice(
        commaNumber(
          Math.floor(
            OrderStore.currentItemTotalPrice *
              (isInitialTeamDealLinkInvited
                ? 1 -
                  (UserStore.linkBenefitsInfo
                    .linkReceiverFirstPurchaseDiscountRate || 0)
                : 1),
          ),
        ),
      )
    } else if (
      OrderStore.currentUserOrdersList[OrderStore.currentItemIndex].isTeamDeal
    ) {
      setDisplayedPrice(
        `${commaNumber(
          Math.floor(teamPurchasePrice) *
            (isInitialTeamDealLinkInvited
              ? 1 -
                (UserStore.linkBenefitsInfo
                  .linkReceiverFirstPurchaseDiscountRate || 0)
              : 1),
        )}~`,
      )
    } else {
      setDisplayedPrice(
        `${commaNumber(
          Math.floor(individualPurchasePrice) *
            (isInitialTeamDealLinkInvited
              ? 1 -
                (UserStore.linkBenefitsInfo
                  .linkReceiverFirstPurchaseDiscountRate || 0)
              : 1),
        )}~`,
      )
    }
  }, [
    OrderStore.currentItemTotalPrice,
    OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]?.isTeamDeal,
    OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
      ?.isInitialTeamDealLinkInvited,
    OrderStore.closeOrderBottomSheet,
  ])
  return (
    <div
      style={{
        // height: 400,
        paddingLeft: 16,
        paddingRight: 16,
        // display: 'flex',
        // flexDirection: 'column',
      }}
    >
      {/* <ToastContainer
        style={{ color: '#333333' }}
        position='top-center'
        autoClose={1500}
        hideProgressBar
        transition={Zoom}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        closeButton={false}
      /> */}
      <div
        style={{
          height: 56,

          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          // paddingBottom: 12,
        }}
      >
        <div style={{ fontSize: 20, color: '#EA3B49' }}>
          {displayedPrice}원
          <div style={{ fontSize: 14, color: '#999999', marginTop: 4 }}>
            팀구매 인원이 모이면 배송이 시작됩니다.
          </div>
        </div>
        <button
          style={{
            display: 'flex',
            width: 24,
            height: 24,
            alignItems: 'center',
            justifyContent: 'center',
            outline: 'none',
            backgroundColor: '#FFF',
            border: 'none',
          }}
          type='button'
          onClick={() => {
            OrderStore.setCloseOrderBottomSheet(true)
            OrderStore.setItemTeamDealIdByIndex(
              null,
              OrderStore.currentItemIndex,
            )
            OrderStore.setItemIsInitialTeamDealLinkInvited(
              false,
              OrderStore.currentItemIndex,
            )
          }}
        >
          <ImageManager source='Delete' size={20} />
        </button>
      </div>
      <div style={{ height: 0.5, backgroundColor: '#E0E0E0' }} />
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <div style={{ position: 'relative' }}>
          {OrderStore.currentUserOrdersList[
            OrderStore.currentItemIndex
          ]?.itemInfo?.optionsInfo.optionNames?.map(
            (value, optionLargeIndex) => {
              return (
                <div
                  key={value.toString() + optionLargeIndex.toString()}
                  style={{ paddingTop: 12, paddingBottom: 12 }}
                >
                  <div style={{ paddingBottom: 6, fontSize: 14 }}>{value}</div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                  >
                    {OrderStore.currentUserOrdersList[
                      OrderStore.currentItemIndex
                    ]?.itemInfo.optionsInfo?.totalOptions[optionLargeIndex].map(
                      (value, index) => {
                        return (
                          <SelectOptionButton
                            key={value.toString() + index.toString()}
                            item={value}
                            checked={
                              (OrderStore.currentUserOrdersList[
                                OrderStore.currentItemIndex
                              ]?.optionIndices &&
                                OrderStore.currentUserOrdersList[
                                  OrderStore.currentItemIndex
                                ].optionIndices[optionLargeIndex] === index) ||
                              (OrderStore.currentUserOrdersList[
                                OrderStore.currentItemIndex
                              ]?.selectedOption &&
                                OrderStore.currentUserOrdersList[
                                  OrderStore.currentItemIndex
                                ].selectedOption[optionLargeIndex] === index)
                            }
                            optionLargeIndex={optionLargeIndex}
                            index={index}
                            correspondingOptionsLength={
                              OrderStore.currentUserOrdersList[
                                OrderStore.currentItemIndex
                              ]?.itemInfo.optionsInfo?.totalOptions[
                                optionLargeIndex
                              ]?.length
                            }
                          />
                        )
                      },
                    )}
                  </div>
                </div>
              )
            },
          )}
        </div>
        <div style={{ height: 0.5, backgroundColor: '#E0E0E0' }} />
        <div
          style={{
            paddingTop: 8,
            paddingBottom: 8,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: 14,
          }}
        >
          <div>주문 수량</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <button
              style={{
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 4,
                paddingBottom: 4,
                backgroundColor: '#F5F5F5',
                borderRadius: 4,
                marginLeft: 4,
                marginRight: 4,
                border: 'none',
              }}
              type='button'
              onClick={() => {
                if (
                  OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
                    ?.quantity > 1
                )
                  OrderStore.setItemQuantityByIndex(
                    OrderStore.currentUserOrdersList[
                      OrderStore.currentItemIndex
                    ]?.quantity - 1,
                    OrderStore.currentItemIndex,
                  )
              }}
            >
              <ImageManager source='MinusSmall' size={16} />
            </button>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: 32,
                alignItems: 'center',
              }}
            >
              {
                OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
                  ?.quantity
              }
            </div>
            <button
              style={{
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 4,
                paddingBottom: 4,
                backgroundColor: '#F5F5F5',
                borderRadius: 4,
                marginLeft: 4,
                marginRight: 4,
                border: 'none',
              }}
              type='button'
              onClick={() => {
                if (
                  OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
                    .quantity <
                  Math.min(
                    OrderStore.currentUserOrdersList[
                      OrderStore.currentItemIndex
                    ].itemInfo.purchaseLimits || 10,
                    OrderStore.currentUserOrdersList[
                      OrderStore.currentItemIndex
                    ].selectedOption?.stockNumber ||
                      OrderStore.currentUserOrdersList[
                        OrderStore.currentItemIndex
                      ].itemInfo.purchaseLimits ||
                      10,
                  )
                ) {
                  OrderStore.setItemQuantityByIndex(
                    OrderStore.currentUserOrdersList[
                      OrderStore.currentItemIndex
                    ].quantity + 1,
                    OrderStore.currentItemIndex,
                  )
                } else if (
                  OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
                    .quantity >=
                  (OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
                    .itemInfo.purchaseLimits || 10)
                ) {
                  console.log(`Maximum purchase limit!!`)
                  notify('1회 주문 가능한 최대 수량입니다.')
                  // toastRef.current.show(
                  //   `1회 주문 가능한 최대 수량입니다.`,
                  //   1000,
                  // )
                } else if (
                  OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
                    .quantity >=
                  OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
                    .selectedOption?.stockNumber
                ) {
                  console.log(`Maximum purchase limit!!`)
                  notify('현재 재고로 주문 가능한 최대 수량입니다.')
                  // notify('현재 재고로 주문 가능한 최대 수량입니다.')
                  // toastRef.current.show(
                  //   `현재 재고로 주문 가능한 최대 수량입니다.`,
                  //   1000,
                  // )
                }
              }}
            >
              <ImageManager source='PlusSmall' size={16} />
            </button>
          </div>
        </div>
      </div>
      <div style={{ height: 0.5, backgroundColor: '#E0E0E0' }} />
      <div style={{ height: 64, backgroundColor: '#FFF' }} />

      <div
        style={{
          paddingRight: 16,
          paddingLeft: 16,
        }}
        className='special'
      >
        <button
          style={{
            width: '100%',
            marginBottom: 16,
            height: 40,
            backgroundColor: '#EA3f49',
            border: 'none',
            borderRadius: 4,
            fontSize: 16,
            color: '#FFF',
          }}
          type='button'
          onClick={async () => {
            // 스토어 링크로 타고 넘어가는 로직
            const { itemId, teamDealId, invitorId, linkType } =
              UserStore.dynamicLinkParams
            UserStore.setIsLoading(true)
            const link = await dynamicLinkUtils.createAppDownloadLink(
              itemId || '',
              teamDealId || '',
              invitorId || '',
              linkType || '',
              '',
              '',
              '',
              'ItemScreenWeb',
              UserStore.dynamicLinkParams.isLinkFromApp,
              UserStore.dynamicLinkParams.bottomSheetTimeOut,
              UserStore.isLateComer,
              UserStore.testGroup?.endedTeamDealNotify,
              UserStore.testGroup?.ableToPurchaseViralItem,
              UserStore.testGroup?.reviewPosition,
              UserStore.testGroup?.teamMemberShowing,
              UserStore.testGroup?.appDownloadFullScreen,
            )
            UserStore.setIsLoading(false)
            if (link) {
              window.location.href = link
              // await copyToClipboard(link)
            }
            // onPressJoinTeamDeal()
            // 스토어로 넘어가는 로직 끝

            // 기존 결제로작
            // if (
            //   OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //     ?.optionIndices &&
            //   Object.keys(
            //     OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //       .optionIndices,
            //   ).length ===
            //     OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //       ?.itemInfo.optionsInfo?.totalOptions.length
            // ) {
            //   console.log(`option complete`)
            //   if (
            //     OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //       ?.itemInfo.isViralItem &&
            //     OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //       ?.selectedOption.isViralOption
            //     //    &&
            //     // OrderStore.loadedUserOrdersList.find((order) => {
            //     //   if (
            //     //     order.status !== 'pre-pay' &&
            //     //     order.itemInfo?.itemId ===
            //     //       OrderStore.currentUserOrdersList[
            //     //         OrderStore.currentItemIndex
            //     //       ]?.itemInfo.itemId &&
            //     //     order.selectedOption?.isViralOption
            //     //   ) {
            //     //     return true
            //     //   }
            //     //   return false
            //     // })
            //   ) {
            //     if (
            //       // !localStorage.getItem('@alwayz@purchaseAvailable') ||
            //       OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //         ?.teamDealId
            //     ) {
            //       OrderStore.setCloseOrderBottomSheet(true)
            //       OrderStore.setItemTeamDealIdByIndex(
            //         null,
            //         OrderStore.currentItemIndex,
            //       )
            //       OrderStore.setItemIsInitialTeamDealLinkInvited(
            //         false,
            //         OrderStore.currentItemIndex,
            //       )
            //       notify(
            //         <div>
            //           <div
            //             style={{
            //               display: 'flex',
            //               justifyContent: 'center',
            //               fontSize: 16,
            //               fontWeight: 'bold',
            //               marginBottom: 32,
            //             }}
            //           >
            //             해당 옵션은 앱에서 구매가 가능해요.
            //           </div>
            //           <button
            //             style={{
            //               width: '100%',
            //               marginBottom: 0,
            //               height: 40,
            //               backgroundColor: '#EA3f49',
            //               border: 'none',
            //               borderRadius: 4,
            //               fontSize: 14,
            //               color: '#FFF',
            //               fontWeight: 'bold',
            //             }}
            //             type='button'
            //             onClick={async () => {
            //               const { itemId, teamDealId, invitorId, linkType } =
            //                 UserStore.dynamicLinkParams
            //               UserStore.setIsLoading(true)
            //               const link =
            //                 await dynamicLinkUtils.createAppDownloadLink(
            //                   itemId || '',
            //                   teamDealId || '',
            //                   invitorId || '',
            //                   linkType || '',
            //                   '',
            //                   '',
            //                   '',
            //                   'BottomSheetViralOptionAppDownloadWeb',
            //                   UserStore.dynamicLinkParams.isLinkFromApp,
            //                   UserStore.dynamicLinkParams.bottomSheetTimeOut,
            //                   UserStore.isLateComer,
            //                   UserStore.testGroup?.endedTeamDealNotify,
            //                   UserStore.testGroup?.ableToPurchaseViralItem,
            //                   UserStore.testGroup?.reviewPosition,
            //                   UserStore.testGroup?.teamMemberShowing,
            //                   UserStore.testGroup?.appDownloadFullScreen,
            //                 )
            //               UserStore.setIsLoading(false)
            //               if (link) {
            //                 console.log(`link: ${link}`)
            //                 window.location.href = link
            //               }
            //             }}
            //           >
            //             앱 다운로드 받기
            //           </button>
            //         </div>,
            //         5000,
            //         toast.POSITION.BOTTOM_CENTER,
            //         // <div>
            //         //   더 많은 분에게 혜택을 드리기 위해서
            //         //   <br />
            //         //   해당 옵션은 현재 중복 구매가 어려워요
            //         // </div>,
            //         //                   `더 많은 분에게 혜택을 드리기 위해서\n
            //         // 해당 옵션은 현재 중복 구매가 어려워요.`,
            //       )
            //       return
            //     }
            //     if (
            //       OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //         .teamDealId &&
            //       OrderStore.loadedUserOrdersList.find((order) => {
            //         if (
            //           [
            //             'team-gathering',
            //             'pre-shipping',
            //             'canceling-pre-shipping',
            //             'shipping',
            //             'canceling-shipping',
            //             'arrived',
            //             'reviewed',
            //             'refunding',
            //           ].includes(order.status) &&
            //           order.itemInfo?.itemId ===
            //             OrderStore.currentUserOrdersList[
            //               OrderStore.currentItemIndex
            //             ]?.itemInfo.itemId &&
            //           order.selectedOption?.isViralOption
            //         ) {
            //           return true
            //         }
            //         return false
            //       })
            //     ) {
            //       OrderStore.setCloseOrderBottomSheet(true)
            //       OrderStore.setItemTeamDealIdByIndex(
            //         null,
            //         OrderStore.currentItemIndex,
            //       )
            //       OrderStore.setItemIsInitialTeamDealLinkInvited(
            //         false,
            //         OrderStore.currentItemIndex,
            //       )
            //       notify(
            //         <div>
            //           <div
            //             style={{
            //               display: 'flex',
            //               justifyContent: 'center',
            //               fontSize: 16,
            //               fontWeight: 'bold',
            //               marginBottom: 32,
            //             }}
            //           >
            //             해당 옵션은 앱에서 구매가 가능해요.
            //           </div>
            //           <button
            //             style={{
            //               width: '100%',
            //               marginBottom: 0,
            //               height: 40,
            //               backgroundColor: '#EA3f49',
            //               border: 'none',
            //               borderRadius: 4,
            //               fontSize: 14,
            //               color: '#FFF',
            //               fontWeight: 'bold',
            //             }}
            //             type='button'
            //             onClick={async () => {
            //               const { itemId, teamDealId, invitorId, linkType } =
            //                 UserStore.dynamicLinkParams
            //               UserStore.setIsLoading(true)
            //               const link =
            //                 await dynamicLinkUtils.createAppDownloadLink(
            //                   itemId || '',
            //                   teamDealId || '',
            //                   invitorId || '',
            //                   linkType || '',
            //                   '',
            //                   '',
            //                   '',
            //                   'BottomSheetViralOptionAppDownloadWeb',
            //                   UserStore.dynamicLinkParams.isLinkFromApp,
            //                   UserStore.dynamicLinkParams.bottomSheetTimeOut,
            //                   UserStore.isLateComer,
            //                   UserStore.testGroup?.endedTeamDealNotify,
            //                   UserStore.testGroup?.ableToPurchaseViralItem,
            //                   UserStore.testGroup?.reviewPosition,
            //                   UserStore.testGroup?.teamMemberShowing,
            //                   UserStore.testGroup?.appDownloadFullScreen,
            //                 )
            //               UserStore.setIsLoading(false)
            //               if (link) {
            //                 console.log(`link: ${link}`)
            //                 window.location.href = link
            //               }
            //             }}
            //           >
            //             앱 다운로드 받기
            //           </button>
            //         </div>,
            //         5000,
            //         toast.POSITION.BOTTOM_CENTER,
            //       )
            //       return
            //     }
            //     // if (
            //     //   localStorage.getItem('@alwayz@purchaseAvailable') &&
            //     //   OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //     //     .teamDealId
            //     // ) {
            //     // }
            //   }
            //   if (
            //     OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //       ?.itemInfo.isLotteryItem
            //   ) {
            //     console.log(
            //       `is lottery item, ${JSON.stringify(
            //         OrderStore.currentUserOrdersList[
            //           OrderStore.currentItemIndex
            //         ],
            //       )}`,
            //     )
            //     if (
            //       OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //         ?.teamDealId ||
            //       OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //         ?.selectedOption?.teamPurchasePrice === 0
            //     ) {
            //       OrderStore.setCloseOrderBottomSheet(true)
            //       OrderStore.setItemTeamDealIdByIndex(
            //         null,
            //         OrderStore.currentItemIndex,
            //       )
            //       OrderStore.setItemIsInitialTeamDealLinkInvited(
            //         false,
            //         OrderStore.currentItemIndex,
            //       )
            //       notify(
            //         <div>
            //           <div
            //             style={{
            //               display: 'flex',
            //               justifyContent: 'center',
            //               fontSize: 16,
            //               fontWeight: 'bold',
            //               marginBottom: 32,
            //             }}
            //           >
            //             해당 상품은 앱에서 구매가 가능해요.
            //           </div>
            //           <button
            //             style={{
            //               width: '100%',
            //               marginBottom: 0,
            //               height: 40,
            //               backgroundColor: '#EA3f49',
            //               border: 'none',
            //               borderRadius: 4,
            //               fontSize: 14,
            //               color: '#FFF',
            //               fontWeight: 'bold',
            //             }}
            //             type='button'
            //             onClick={async () => {
            //               const { itemId, teamDealId, invitorId, linkType } =
            //                 UserStore.dynamicLinkParams
            //               UserStore.setIsLoading(true)
            //               const link =
            //                 await dynamicLinkUtils.createAppDownloadLink(
            //                   itemId || '',
            //                   teamDealId || '',
            //                   invitorId || '',
            //                   linkType || '',
            //                   '',
            //                   '',
            //                   '',
            //                   'BottomSheetLotteryItemAppDownloadWeb',
            //                   UserStore.dynamicLinkParams.isLinkFromApp,
            //                   UserStore.dynamicLinkParams.bottomSheetTimeOut,
            //                   UserStore.isLateComer,
            //                   UserStore.testGroup?.endedTeamDealNotify,
            //                   UserStore.testGroup?.ableToPurchaseViralItem,
            //                   UserStore.testGroup?.reviewPosition,
            //                   UserStore.testGroup?.teamMemberShowing,
            //                   UserStore.testGroup?.appDownloadFullScreen,
            //                 )
            //               UserStore.setIsLoading(false)
            //               if (link) {
            //                 console.log(`link: ${link}`)
            //                 window.location.href = link
            //               }
            //             }}
            //           >
            //             앱 다운로드 받기
            //           </button>
            //         </div>,
            //         5000,
            //         toast.POSITION.BOTTOM_CENTER,
            //         // <div>
            //         //   더 많은 분에게 혜택을 드리기 위해서
            //         //   <br />
            //         //   해당 옵션은 현재 중복 구매가 어려워요
            //         // </div>,
            //         //                   `더 많은 분에게 혜택을 드리기 위해서\n
            //         // 해당 옵션은 현재 중복 구매가 어려워요.`,
            //       )
            //       return
            //     }
            //   }
            //   if (
            //     OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //       ?.itemInfo.characteristicItem
            //   ) {
            //     if (
            //       OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //         ?.teamDealId ||
            //       OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //         ?.selectedOption?.teamPurchasePrice === 0
            //     ) {
            //       OrderStore.setCloseOrderBottomSheet(true)
            //       OrderStore.setItemTeamDealIdByIndex(
            //         null,
            //         OrderStore.currentItemIndex,
            //       )
            //       OrderStore.setItemIsInitialTeamDealLinkInvited(
            //         false,
            //         OrderStore.currentItemIndex,
            //       )
            //       notify(
            //         <div>
            //           <div
            //             style={{
            //               display: 'flex',
            //               justifyContent: 'center',
            //               fontSize: 16,
            //               fontWeight: 'bold',
            //               marginBottom: 32,
            //             }}
            //           >
            //             해당 상품은 앱에서 구매가 가능해요.
            //           </div>
            //           <button
            //             style={{
            //               width: '100%',
            //               marginBottom: 0,
            //               height: 40,
            //               backgroundColor: '#EA3f49',
            //               border: 'none',
            //               borderRadius: 4,
            //               fontSize: 14,
            //               color: '#FFF',
            //               fontWeight: 'bold',
            //             }}
            //             type='button'
            //             onClick={async () => {
            //               const { itemId, teamDealId, invitorId, linkType } =
            //                 UserStore.dynamicLinkParams
            //               UserStore.setIsLoading(true)
            //               const link =
            //                 await dynamicLinkUtils.createAppDownloadLink(
            //                   itemId || '',
            //                   teamDealId || '',
            //                   invitorId || '',
            //                   linkType || '',
            //                   '',
            //                   '',
            //                   '',
            //                   'BottomSheetLotteryItemAppDownloadWeb',
            //                   UserStore.dynamicLinkParams.isLinkFromApp,
            //                   UserStore.dynamicLinkParams.bottomSheetTimeOut,
            //                   UserStore.isLateComer,
            //                   UserStore.testGroup?.endedTeamDealNotify,
            //                   UserStore.testGroup?.ableToPurchaseViralItem,
            //                   UserStore.testGroup?.reviewPosition,
            //                   UserStore.testGroup?.teamMemberShowing,
            //                   UserStore.testGroup?.appDownloadFullScreen,
            //                 )
            //               UserStore.setIsLoading(false)
            //               if (link) {
            //                 console.log(`link: ${link}`)
            //                 window.location.href = link
            //               }
            //             }}
            //           >
            //             앱 다운로드 받기
            //           </button>
            //         </div>,
            //         5000,
            //         toast.POSITION.BOTTOM_CENTER,
            //         // <div>
            //         //   더 많은 분에게 혜택을 드리기 위해서
            //         //   <br />
            //         //   해당 옵션은 현재 중복 구매가 어려워요
            //         // </div>,
            //         //                   `더 많은 분에게 혜택을 드리기 위해서\n
            //         // 해당 옵션은 현재 중복 구매가 어려워요.`,
            //       )
            //       return
            //     }
            //   }
            //   if (
            //     OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            //       ?.itemInfo.isMartItem
            //   ) {
            //     notify(
            //       <div>
            //         <div
            //           style={{
            //             display: 'flex',
            //             justifyContent: 'center',
            //             fontSize: 16,
            //             fontWeight: 'bold',
            //             marginBottom: 32,
            //           }}
            //         >
            //           해당 상품은 앱에서 구매가 가능해요.
            //         </div>
            //         <button
            //           style={{
            //             width: '100%',
            //             marginBottom: 0,
            //             height: 40,
            //             backgroundColor: '#EA3f49',
            //             border: 'none',
            //             borderRadius: 4,
            //             fontSize: 14,
            //             color: '#FFF',
            //             fontWeight: 'bold',
            //           }}
            //           type='button'
            //           onClick={async () => {
            //             const { itemId, teamDealId, invitorId, linkType } =
            //               UserStore.dynamicLinkParams
            //             UserStore.setIsLoading(true)
            //             const link =
            //               await dynamicLinkUtils.createAppDownloadLink(
            //                 itemId || '',
            //                 teamDealId || '',
            //                 invitorId || '',
            //                 linkType || '',
            //                 '',
            //                 '',
            //                 '',
            //                 'BottomSheetLotteryItemAppDownloadWeb',
            //                 UserStore.dynamicLinkParams.isLinkFromApp,
            //                 UserStore.dynamicLinkParams.bottomSheetTimeOut,
            //                 UserStore.isLateComer,
            //                 UserStore.testGroup?.endedTeamDealNotify,
            //                 UserStore.testGroup?.ableToPurchaseViralItem,
            //                 UserStore.testGroup?.reviewPosition,
            //                 UserStore.testGroup?.teamMemberShowing,
            //                 UserStore.testGroup?.appDownloadFullScreen,
            //               )
            //             UserStore.setIsLoading(false)
            //             if (link) {
            //               console.log(`link: ${link}`)
            //               window.location.href = link
            //             }
            //           }}
            //         >
            //           앱 다운로드 받기
            //         </button>
            //       </div>,
            //       5000,
            //       toast.POSITION.BOTTOM_CENTER,
            //       // <div>
            //       //   더 많은 분에게 혜택을 드리기 위해서
            //       //   <br />
            //       //   해당 옵션은 현재 중복 구매가 어려워요
            //       // </div>,
            //       //                   `더 많은 분에게 혜택을 드리기 위해서\n
            //       // 해당 옵션은 현재 중복 구매가 어려워요.`,
            //     )
            //     return
            //   }
            //   OrderStore.setCloseOrderBottomSheet(true)
            //   if (OrderStore.currentUserOrdersList.length === 1) {
            //     //   navigation.navigate('OrderMain')
            //     history.push(`/order-main/${ItemStore.targetItemInfo._id}`)
            //   }
            // } else {
            //   // checked
            //   alert(
            //     '아직 선택되지 않은 옵션이 있습니다. 옵션을 전부 선택해주세요.',
            //   )
            // }
            // 기존 결제로직 끝
          }}
        >
          {OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
            ?.isTeamDeal
            ? `${
                OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
                  ?.teamDealId
                  ? ''
                  : `${
                      OrderStore.currentUserOrdersList[
                        OrderStore.currentItemIndex
                      ]?.itemInfo.teamDealThreshold || 2
                    }인 `
              }팀구매 ${
                OrderStore.currentUserOrdersList[OrderStore.currentItemIndex]
                  ?.teamDealId
                  ? '참여하기'
                  : '열기'
              }`
            : '혼자 구매하기'}
        </button>
      </div>
    </div>
  )
})

export default ItemOrderBottomSheet
